/* eslint-disable no-empty */
/* eslint-disable fp/no-let */

import { SCROLL_DELAY } from './CmsPage.config';

export { default } from './CmsPage.container';

/** @namespace Theme/Route/CmsPage/Index/goto */
export function goto(id, event, timeout = 0) {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    event.stopPropagation();
    event.preventDefault();
    setTimeout(() => {
        window.location.hash = id;
    }, timeout);
}

document.addEventListener('click', (event) => {
    const el = event.target;

    if (el.classList.contains('CmsPage-GlossaryLink')) {
        goto((new URL(el.href)).hash.substr(1), event, SCROLL_DELAY);
    } else if (el.matches('main.CmsPage a')) {
        const hashPos = el.href.indexOf('#');
        let isExternal = false;
        let hasTarget = false;
        try {
            isExternal = (new URL(el.href).hostname) !== window.location.hostname;
            hasTarget = document.querySelector((new URL(el.href)).hash);
        } catch {}

        if ((hashPos > -1) && !isExternal && hasTarget) {
            goto(el.href.substr(hashPos + 1), event);
        }
    }
}, true);

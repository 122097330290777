import {
    CmsPageQuery as SourceCmsPageQuery,
} from 'SourceQuery/CmsPage.query';

/** @namespace Theme/Query/CmsPage/Query */
export class CmsPageQuery extends SourceCmsPageQuery {
    _getPageFields() {
        return [
            ...super._getPageFields(),
            'hide_from_seo',
        ];
    }
}

export default new CmsPageQuery();

/* eslint-disable fp/no-let */
import { connect } from 'react-redux';

import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import { ___ } from 'Util/Format';

import { IFRAME_ADJUST_HEIGHT_INTERVAL } from './CmsPage.config';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Route/CmsPage/Container */
export class CmsPageContainer extends SourceCmsPageContainer {
    cmsPageUpdateMeta() {
        const { cmsPage, updateMeta } = this.props;
        const {
            hide_from_seo,
            meta_title,
            title,
            meta_description,
            meta_keywords,
        } = cmsPage;

        updateMeta({
            robots: hide_from_seo ? 'nofollow, noindex' : null,
            title: ___(meta_title || title),
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });
    }

    onPageLoad() {
        super.onPageLoad();
        this.cmsPageUpdateMeta();

        let anchorElement;

        if (window.location.hash.indexOf('#') >= 0) {
            anchorElement = document.querySelector(window.location.hash);
        }

        if (anchorElement) {
            const headerOffset = 120;
            const elementPosition = anchorElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }

        // This is intended to adjust the height of the technical support form
        const iframeToAdjust = document.querySelector('iframe.js-dynamically-adjust-height');

        if (window.iframeAdjustIntervalId) {
            clearInterval(window.iframeAdjustIntervalId);
        }

        if (iframeToAdjust) {
            window.iframeAdjustIntervalId = window.setInterval(() => {
                iframeToAdjust.height = iframeToAdjust.contentDocument.body.clientHeight;
            }, IFRAME_ADJUST_HEIGHT_INTERVAL);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            updateBreadcrumbs,
            cmsPage,
        } = this.props;

        super.componentDidUpdate(prevProps);
        updateBreadcrumbs(cmsPage);
        this.cmsPageUpdateMeta();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
